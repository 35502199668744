import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IExternalApp } from 'src/app/core/models/api/external-apps';

export class EditCompanyViewModel {
  private _form: FormGroup;
  private _externalApps: Array<IExternalApp> = [];

  readonly C_COMPANY_ID = 'roleId';
  readonly C_COMPANY_NAME = 'name';
  readonly C_EXTERNAL_APP_ID = 'externalAppId';

  constructor() {
    this._form = new FormGroup({
      [this.C_COMPANY_ID]: new FormControl(null, []),
      [this.C_COMPANY_NAME]: new FormControl(null, [Validators.required]),
      [this.C_EXTERNAL_APP_ID]: new FormControl(null, [Validators.required])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get externalApps(): Array<IExternalApp> {
    return this._externalApps;
  }

  set externalApps(value: Array<IExternalApp>) {
    this._externalApps = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  get companyID(): number | null | undefined {
    return this.getControl(this.C_COMPANY_ID).value;
  }

  set companyID(value: number | null | undefined) {
    this.getControl(this.C_COMPANY_ID).setValue(value);
  }

  get companyName(): string {
    return this.getControl(this.C_COMPANY_NAME).value;
  }

  set companyName(value: string) {
    this.getControl(this.C_COMPANY_NAME).setValue(value);
  }

  get externalAppID(): number {
    return this.getControl(this.C_EXTERNAL_APP_ID).value;
  }

  set externalAppID(value: number) {
    this.getControl(this.C_EXTERNAL_APP_ID).setValue(value);
  }
}
