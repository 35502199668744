import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IExternalApp } from 'src/app/core/models/api/external-apps';
import { IUser } from 'src/app/core/models/api/users';

export class EditExternalAppCredentialViewModel {
  private _form: FormGroup;
  private _users: Array<IUser> = [];
  private _externalApps: Array<IExternalApp> = [];

  readonly C_EXTERNAL_CREDENTIAL_ID = 'externalCredentialId';
  readonly C_EXTERNAL_APP_ID = 'appId';
  readonly C_USER_ID = 'userId';
  readonly C_EXTERNAL_USERNAME = 'externalUserName';
  readonly C_EXTERNAL_PASSWORD = 'externalPassword';
  readonly C_EXTERNAL_USER_ID = 'externalUserId';
  readonly C_TOKEN = 'token';
  readonly C_DEFAULT_CREDENTIAL = 'defaultCredential';
  readonly C_STATUS = 'status';

  constructor() {
    this._form = new FormGroup({
      [this.C_EXTERNAL_CREDENTIAL_ID]: new FormControl(null, []),
      [this.C_EXTERNAL_APP_ID]: new FormControl(null, [Validators.required]),
      [this.C_USER_ID]: new FormControl(null, [Validators.required]),
      [this.C_EXTERNAL_USERNAME]: new FormControl(null, []),
      [this.C_EXTERNAL_PASSWORD]: new FormControl(null, []),
      [this.C_EXTERNAL_USER_ID]: new FormControl(null, []),
      [this.C_TOKEN]: new FormControl(null, []),
      [this.C_DEFAULT_CREDENTIAL]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(null, [])
    });
  }

  get users(): Array<IUser> {
    return this._users;
  }

  set users(value: Array<IUser>) {
    this._users = value;
  }

  get externalApps(): Array<IExternalApp> {
    return this._externalApps;
  }

  set externalApps(value: Array<IExternalApp>) {
    this._externalApps = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get externalCredentialID(): number | null | undefined {
    return this.getControl(this.C_EXTERNAL_CREDENTIAL_ID).value;
  }

  set externalCredentialID(value: number | null | undefined) {
    this.getControl(this.C_EXTERNAL_CREDENTIAL_ID).setValue(value);
  }

  get externalAppID(): number | null | undefined {
    return this.getControl(this.C_EXTERNAL_APP_ID).value;
  }

  set externalAppID(value: number | null | undefined) {
    this.getControl(this.C_EXTERNAL_APP_ID).setValue(value);
  }

  get userID(): number | null | undefined {
    return this.getControl(this.C_USER_ID).value;
  }

  set userID(value: number | null | undefined) {
    this.getControl(this.C_USER_ID).setValue(value);
  }

  get externalUserName(): string | null | undefined {
    return this.getControl(this.C_EXTERNAL_USERNAME).value;
  }

  set externalUserName(value: string | null | undefined) {
    this.getControl(this.C_EXTERNAL_USERNAME).setValue(value);
  }

  get password(): string | null | undefined {
    return this.getControl(this.C_EXTERNAL_PASSWORD).value;
  }

  set password(value: string | null | undefined) {
    this.getControl(this.C_EXTERNAL_PASSWORD).setValue(value);
  }

  get externalUserID(): string | null | undefined {
    return this.getControl(this.C_EXTERNAL_USER_ID).value;
  }

  set externalUserID(value: string | null | undefined) {
    this.getControl(this.C_EXTERNAL_USER_ID).setValue(value);
  }

  get token(): string | null | undefined {
    return this.getControl(this.C_TOKEN).value;
  }

  set token(value: string | null | undefined) {
    this.getControl(this.C_TOKEN).setValue(value);
  }

  get defaultCredential(): boolean | null | undefined {
    return this.getControl(this.C_DEFAULT_CREDENTIAL).value;
  }

  set defaultCredential(value: boolean | null | undefined) {
    this.getControl(this.C_DEFAULT_CREDENTIAL).setValue(value);
  }

  get status(): number | null | undefined {
    return this.getControl(this.C_STATUS).value;
  }

  set status(value: number | null | undefined) {
    this.getControl(this.C_STATUS).setValue(value);
  }
}
