import { Injectable } from '@angular/core';
import { GENERAL_CONSTANTS, HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IRole } from 'src/app/core/models/api/roles';
import { IRolesService } from './roles.contract';

@Injectable({
  providedIn: 'root'
})
export class RolesService implements IRolesService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  getAll(): Observable<Array<IRole>> {
    return this.httpClient.get<Array<IRole>>(GENERAL_CONSTANTS.ApiSecurityName, '/api/roles');
  }
}
