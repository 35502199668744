import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenTableComponent, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IUserCompany } from 'src/app/core/models/api/users';
import { IApplicationsService } from 'src/app/core/services/api/applications';
import { ICompaniesService } from 'src/app/core/services/api/companies';
import { IRolesService } from 'src/app/core/services/api/roles';
import { IUsersService } from 'src/app/core/services/api/users';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { UserEmailValidator, UsernameValidator } from 'src/app/shared/validators';
import { SubSink } from 'subsink';
import { EditUserMapper } from '../mappers';
import { EditUserViewModel, IRoleViewModel } from '../models';

@Component({
  selector: 'howden-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit, OnDestroy {
  model: EditUserViewModel;

  @ViewChild('companiesTable') companiesTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EditUserDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private userNameVal: UsernameValidator,
    private userEmailVal: UserEmailValidator,
    private appSrv: IApplicationsService,
    private companySrv: ICompaniesService,
    private roleSrv: IRolesService,
    private userSrv: IUsersService
  ) {
    this.model = new EditUserViewModel(this.userNameVal, this.userEmailVal);
  }

  ngOnInit(): void {
    const id = this.inputData.id;
    const username = this.inputData.username;
    const email = this.inputData.email;

    this.uiBlockerSrv.block();

    forkJoin([
      this.appSrv.getAll(),
      this.companySrv.getAll(),
      this.roleSrv.getAll(),
      this.userSrv.getByUsername(username),
      this.userSrv.getCompanies(id),
      this.userSrv.getRoles(username),
      this.userSrv.getSecondaryLogo(email)
    ]).subscribe(([apps, companies, roles, user, userCompanies, userRoles, secondaryLogo]) => {
      EditUserMapper.mapForEdit(user, this.model);
      this.model.secondaryLogo = secondaryLogo?.logo;
      this.model.userCompanies = userCompanies;
      this.model.companies = companies;
      this.model.userRoles = userRoles.map(x => ({ rolName: x } as IRoleViewModel));
      this.model.roles = roles.map(x => {
        const app = apps.find(a => a.appId === x.appId);
        return {
          id: x.id,
          app: app?.name,
          rolName: x.rolName,
          isAdmin: x.isAdmin
        } as IRoleViewModel;
      });

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  get disableAccept() {
    return this.model.form.invalid
      || this.model.userRoles.length === 0;
    // || this.companiesTable.selectedRows().length === 0
  }

  onAccept() {
    // const companies = this.companiesTable.selectedRows();
    const roles = this.model.userRoles.map(x => x.rolName as string);
    const companies = new Array<number>();
    const request = EditUserMapper.mapForUpdate(this.model, companies, roles);

    this.uiBlockerSrv.block();
    this.userSrv.save(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCompanyAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.onRemoveCompany(event.row.companyID as number);
    }
  }

  onAssignCompany(): void {
    const title: string = $localize`: @@app.users.edit.assign.company.title:Asignación de empresas`;
    const question: string = $localize`: @@app.users.edit.assign.company.subtitle:Se asignará la empresa seleccionada, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openHelp(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const companyID = this.model.newCompany as number;
          this.uiBlockerSrv.block();
          this.userSrv.addToCompany(this.model.userName as string, companyID).pipe(
            tap({
              next: (data: IUserCompany) => {
                this._subscriptions.sink = this.confirmDialogSrv
                  .openDefault(title)
                  .subscribe(() => {
                    if (!this.model.userCompanies.find(x => x.companyID === companyID)) {
                      this.model.userCompanies = [data, ...this.model.userCompanies];
                      this.model.newCompany = undefined;
                    }
                  });
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onRoleAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.onRemoveRole(event.row.rolName);
    }
  }

  onAssignRole(): void {
    const title: string = $localize`: @@app.users.edit.assign.role.title:Asignación de roles`;
    const question: string = $localize`: @@app.users.edit.assign.role.subtitle:Se asignará el rol seleccionado, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openHelp(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const role = this.model.newRole as string;
          this.uiBlockerSrv.block();
          this.userSrv.addToRole(this.model.userName as string, role).pipe(
            tap({
              next: () => {
                this._subscriptions.sink = this.confirmDialogSrv
                  .openDefault(title)
                  .subscribe(() => {
                    if (!this.model.userRoles.find(x => x.rolName === role)) {
                      const roleInstance = { rolName: role } as IRoleViewModel;
                      this.model.userRoles = [roleInstance, ...this.model.userRoles];
                      this.model.newRole = undefined;
                    }
                  });
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onRemoveCompany(companyID: number): void {
    const title: string = $localize`: @@app.users.edit.remove.company.title:Desasignar compañía`;
    const question: string = $localize`: @@app.users.edit.remove.company.subtitle:Se desasignará la compañía, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.userSrv.removeFromCompany(this.model.userName as string, companyID).pipe(
            tap({
              next: () => {
                this._subscriptions.sink = this.confirmDialogSrv
                  .openDefault(title)
                  .subscribe(() => {
                    this.model.userCompanies = this.model.userCompanies.filter(item => item.companyID !== companyID);
                  });
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onRemoveRole(roleName: string): void {
    const title: string = $localize`: @@app.users.edit.remove.role.title:Desasignación de roles`;
    const question: string = $localize`: @@app.users.edit.remove.role.subtitle:Se desasignará el rol, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.userSrv.removeFromRole(this.model.userName as string, roleName).pipe(
            tap({
              next: () => {
                this._subscriptions.sink = this.confirmDialogSrv
                  .openDefault(title)
                  .subscribe(() => {
                    this.model.userRoles = this.model.userRoles.filter(item => item.rolName !== roleName);
                  });
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
