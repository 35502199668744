import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAskForDataModule,
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { ExternalAppsService, IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { ExternalCredentialsService, IExternalCredentialsService } from 'src/app/core/services/api/external-credentials';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { EditExternalAppCredentialDialogComponent } from './external-app-credentials-search/dialogs/edit-external-app-credential-dialog/pages/edit-external-app-credential-dialog.component';
import { ExternalAppCredentialsSearchComponent } from './external-app-credentials-search/pages/external-app-credentials-search.component';
import { ExternalAppsRoutingModule } from './external-apps-routing.module';
import { EditExternalAppDialogComponent } from './external-apps-search/dialogs/edit-external-app-dialog/pages/edit-external-app-dialog.component';
import { ExternalAppsSearchComponent } from './external-apps-search/pages/external-apps-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    MatTooltipModule,
    HowdenFileUploadModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    HowdenAutocompleteModule,
    HowdenAskForDataModule,
    ExternalAppsRoutingModule
  ],
  declarations: [
    ExternalAppsSearchComponent,
    EditExternalAppDialogComponent,
    ExternalAppCredentialsSearchComponent,
    EditExternalAppCredentialDialogComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: IExternalAppsService, useClass: ExternalAppsService },
    { provide: IExternalCredentialsService, useClass: ExternalCredentialsService }
  ]
})
export class ExternalAppsModule {
}
