import { ICompany } from 'src/app/core/models/api/companies';
import { EditCompanyViewModel } from '../models';

export abstract class EditCompanyMapper {
  static mapForEdit(source: ICompany, target: EditCompanyViewModel) {
    target.companyID = source.companyID as number;
    target.companyName = source.companyName as string;
    target.externalAppID = source.externalAppID as number;
  }

  static mapForUpdate(source: EditCompanyViewModel): ICompany {
    return {
      companyID: source.companyID,
      companyName: source.companyName,
      externalAppID: source.externalAppID
    } as ICompany;
  }
}
