import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IUserSearch } from 'src/app/core/models/api/users';
import { IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { IExternalCredentialsService } from 'src/app/core/services/api/external-credentials';
import { IUsersService } from 'src/app/core/services/api/users';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditExternalAppCredentialMapper } from '../mappers';
import { EditExternalAppCredentialViewModel } from '../models';

@Component({
  selector: 'howden-edit-external-app-credential-dialog',
  templateUrl: './edit-external-app-credential-dialog.component.html',
  styleUrls: ['./edit-external-app-credential-dialog.component.scss']
})
export class EditExternalAppCredentialDialogComponent implements OnInit, OnDestroy {
  model = new EditExternalAppCredentialViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EditExternalAppCredentialDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private userSrv: IUsersService,
    private externalAppSrv: IExternalAppsService,
    private externalCredentialsSrv: IExternalCredentialsService
  ) {
  }

  ngOnInit(): void {
    const id = this.inputData.id;

    if (id) {
      const request = {
        pageNumber: 0,
        pageSize: 9999
      } as IUserSearch;

      this.uiBlockerSrv.block();

      forkJoin([
        this.userSrv.search(request),
        this.externalAppSrv.getAll(),
        this.externalCredentialsSrv.get(id)
      ]).subscribe(([users, externalApps, credential]) => {
        EditExternalAppCredentialMapper.mapForEdit(credential, this.model);
        this.model.externalCredentialID = id;
        this.model.users = users.data;
        this.model.externalApps = externalApps;
        this.uiBlockerSrv.unblock();
      });
    } else {
      const request = {
        pageNumber: 0,
        pageSize: 9999
      } as IUserSearch;

      this.uiBlockerSrv.block();

      forkJoin([
        this.userSrv.search(request),
        this.externalAppSrv.getAll()
      ]).subscribe(([users, externalApps]) => {
        this.model.users = users.data;
        this.model.externalApps = externalApps;
        this.uiBlockerSrv.unblock();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const request = EditExternalAppCredentialMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.externalCredentialsSrv.upsert(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
