import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogResultTypes, HowdenAskForDataService, IButtonActionData, IHowdenAskForData, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IApplication } from 'src/app/core/models/api/applications/application';
import { IApplicationsService } from 'src/app/core/services/api/applications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { ApplicationSearchMapper } from '../mappers';
import { ApplicationSearchViewModel } from '../models';

@Component({
  selector: 'howden-application-search',
  templateUrl: './application-search.component.html',
  styleUrls: ['./application-search.component.scss']
})
export class ApplicationSearchComponent implements OnInit, OnDestroy {
  model = new ApplicationSearchViewModel();

  private _subscriptions = new SubSink();

  @ViewChild('nameInput') nameInputRef!: ElementRef;

  constructor(
    private router: Router,
    private askForDataSrv: HowdenAskForDataService,
    private uiBlockerSrv: IUIBlockerService,
    private appSrv: IApplicationsService
  ) {
  }

  ngOnInit(): void {
    this.loadData(true);
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.loadData();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.router.navigate(['applications/edit', event.row.appId]);
    }
  }

  onNewApp(): void {
    const options = {
      title: $localize`:@@app.applications.create.title:Alta de aplicaciones`,
      subTitle: $localize`:@@app.applications.create.subtitle:Indique los datos de la nueva aplicación`,
      placeHolder: $localize`:@@app.applications.create.placeholder:Indique el nombre de la aplicación`,
      label: $localize`:@@app.route.applications.create.label:Nombre`,
      inputType: 'text',
      allowCancel: true
    } as IHowdenAskForData;

    this._subscriptions.sink = this.askForDataSrv.open(options).pipe(
      tap(result => {
        if (result.result === DialogResultTypes.OK && result.resultData) {
          const request = {
            name: result.resultData,
            status: 1
          } as IApplication;
          this.appSrv.upsert(request).subscribe(() => this.loadData());
        }
      })
    ).subscribe();
  }

  private loadData(initial?: boolean): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.appSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IApplication>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => ApplicationSearchMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();

        if (initial === true) {
          this.nameInputRef.nativeElement.focus();
        }
      }
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.loadData();
    });
  }
}
