<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.edit.external-app.credential.edit.title" *ngIf="model.externalCredentialID">Modificación de credenciales de ERPs externos</div>
      <div class="title-text" i18n="@@app.edit.external-app.credential.create.title2" *ngIf="model.externalCredentialID">Alta de credenciales de ERPs externos</div>
      <div class="subtitle-text"></div>
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <howden-autocomplete
          class="f-basis--12"
          i18n-label="@@app.edit.external-app.credential.erp.label"
          label="ERP"
          displayField="name"
          keyField="externalAppID"
          [selectableItems]="model.externalApps"
          [formControlName]="model.C_EXTERNAL_APP_ID"
        >
        </howden-autocomplete>
      </div>
      <div class="flex f-gap--10px">
        <howden-autocomplete
          class="f-basis--12"
          i18n-label="@@app.edit.external-app.credential.user.label"
          label="Usuario"
          displayField="userName"
          keyField="id"
          [selectableItems]="model.users"
          [formControlName]="model.C_USER_ID"
        >
        </howden-autocomplete>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.external-app.credential.name.label">Usuario (Externo)</mat-label>
          <input matInput [formControlName]="model.C_EXTERNAL_USERNAME" [howdenControlError]="externalUserNameError" maxlength="100" />
          <mat-error #externalUserNameError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.external-app.credential.password.label">Contraseña</mat-label>
          <input
            matInput
            [formControlName]="model.C_EXTERNAL_PASSWORD"
            [howdenControlError]="externalPasswordError"
            type="password"
            maxlength="100"
          />
          <mat-error #externalPasswordError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.external-app.credential.phone.label">ID. Usuario ERP</mat-label>
          <input matInput [formControlName]="model.C_EXTERNAL_USER_ID" [howdenControlError]="externalUserId" maxlength="100" />
          <mat-error #externalUserId></mat-error>
        </mat-form-field>
      </div>
      <div class="flex margin-bottom-20">
        <mat-slide-toggle class="f-basis--6" [formControlName]="model.C_DEFAULT_CREDENTIAL" i18n="@@app.edit.external-app.credential.default.label">
          Credencial por defecto
        </mat-slide-toggle>
        <mat-slide-toggle class="f-basis--6" [formControlName]="model.C_STATUS" i18n="@@app.edit.external-app.credential.status.label">
          Activo
        </mat-slide-toggle>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
