import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class EditRoleViewModel {
  private _form: FormGroup;

  readonly C_APP_ID = 'appId';
  readonly C_ROLE_ID = 'roleId';
  readonly C_NAME = 'name';
  readonly C_IS_ADMIN = 'isAdmin';

  constructor() {
    this._form = new FormGroup({
      [this.C_APP_ID]: new FormControl(null, []),
      [this.C_ROLE_ID]: new FormControl(null, []),
      [this.C_NAME]: new FormControl(null, [Validators.required]),
      [this.C_IS_ADMIN]: new FormControl(false, [Validators.required])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get appId(): number {
    return this.getControl(this.C_APP_ID).value;
  }

  set appId(value: number) {
    this.getControl(this.C_APP_ID).setValue(value);
  }

  get roleId(): number {
    return this.getControl(this.C_ROLE_ID).value;
  }

  set roleId(value: number) {
    this.getControl(this.C_ROLE_ID).setValue(value);
  }

  get name(): string {
    return this.getControl(this.C_NAME).value;
  }

  set name(value: string) {
    this.getControl(this.C_NAME).setValue(value);
  }

  get isAdmin(): boolean {
    return this.getControl(this.C_IS_ADMIN).value;
  }

  set isAdmin(value: boolean) {
    this.getControl(this.C_IS_ADMIN).setValue(value);
  }
}
