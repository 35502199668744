import { FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { IUserSearch } from 'src/app/core/models/api/users';
import { IUserSearchResultViewModel } from './user-search-result.viewmodel';

export class UserSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IUserSearch;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_NAME = 'name';
  readonly C_SURNAME = 'surname';
  readonly C_EMAIL = 'email';
  readonly C_USERNAME = 'username';
  readonly C_STATUS = 'status';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_NAME]: new FormControl('', []),
      [this.C_SURNAME]: new FormControl('', []),
      [this.C_EMAIL]: new FormControl('', []),
      [this.C_USERNAME]: new FormControl('', []),
      [this.C_STATUS]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IUserSearch;
  }

  data: IUserSearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'name',
      fieldHeader: $localize`:@@app.users.search.name.column:Nombre`,
      canSort: true
    },
    {
      fieldName: 'surname',
      fieldHeader: $localize`:@@app.users.search.surname.column:Apellidos`,
      canSort: true
    },
    {
      fieldName: 'email',
      fieldHeader: $localize`:@@app.users.search.email.column:Email`,
      canSort: true
    },
    {
      fieldName: 'userName',
      fieldHeader: $localize`:@@app.users.search.username.column:Usuario`,
      canSort: true
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.users.search.status.column:Estado`,
      canSort: false
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.users.search.actions.edit.tooltip:Editar`
  }, {
    name: 'preferences',
    icon: 'settings_account_box',
    color: TableButtonColors.FILLER,
    tooltip: $localize`:@@app.users.search.actions.preferences.tooltip:Preferencias de usuario`
  }, {
    name: 'external-pass',
    icon: 'password',
    color: TableButtonColors.CAUTION,
    tooltip: $localize`:@@app.users.search.actions.credentials.tooltip:Credenciales (login externo)`,
    isDisabled: (row: any): boolean => !row.hasExternalPass
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get searchRequest(): IUserSearch {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get name(): string | null | undefined {
    return this._filterForm.get(this.C_NAME)?.value;
  }

  set name(value: string | null | undefined) {
    this._filterForm.get(this.C_NAME)?.setValue(value);
  }

  get surname(): string | null | undefined {
    return this._filterForm.get(this.C_SURNAME)?.value;
  }

  set surname(value: string | null | undefined) {
    this._filterForm.get(this.C_SURNAME)?.setValue(value);
  }

  get email(): string | null | undefined {
    return this._filterForm.get(this.C_EMAIL)?.value;
  }

  set email(value: string | null | undefined) {
    this._filterForm.get(this.C_EMAIL)?.setValue(value);
  }

  get userName(): string | null | undefined {
    return this._filterForm.get(this.C_USERNAME)?.value;
  }

  set userName(value: string | null | undefined) {
    this._filterForm.get(this.C_USERNAME)?.setValue(value);
  }

  get status(): number | null | undefined {
    return this._filterForm.get(this.C_STATUS)?.value;
  }

  set status(value: number | null | undefined) {
    this._filterForm.get(this.C_STATUS)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    const status = this._filterForm.get(this.C_STATUS)?.value;
    this.searchRequest.name = this.name;
    this.searchRequest.surname = this.surname;
    this.searchRequest.email = this.email;
    this.searchRequest.userName = this.userName;
    this.searchRequest.status = !status || status === '' ? null : parseInt(status);
  }
}
