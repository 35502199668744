import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { ICompaniesService } from 'src/app/core/services/api/companies';
import { IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditCompanyMapper } from '../mappers';
import { EditCompanyViewModel } from '../models';

@Component({
  selector: 'howden-edit-company-dialog',
  templateUrl: './edit-company-dialog.component.html',
  styleUrls: ['./edit-company-dialog.component.scss']
})
export class EditCompanyDialogComponent implements OnInit, OnDestroy {
  model = new EditCompanyViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EditCompanyDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private companiesSrv: ICompaniesService,
    private externalAppsSrv: IExternalAppsService
  ) {
  }

  ngOnInit(): void {
    const companyID = this.inputData.companyID;

    if (typeof companyID !== 'undefined' && companyID !== null) {
      this.uiBlockerSrv.block();

      forkJoin([
        this.companiesSrv.get(companyID),
        this.externalAppsSrv.getAll()
      ]).subscribe(([company, externalApps]) => {
        EditCompanyMapper.mapForEdit(company, this.model);
        this.model.externalApps = externalApps;

        this.uiBlockerSrv.unblock();
      });
    } else {
      this.model.companyID = null;
      this.externalAppsSrv.getAll().subscribe(data => this.model.externalApps = data);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const request = EditCompanyMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.companiesSrv.upsert(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
