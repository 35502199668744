import { Injectable } from '@angular/core';
import { GENERAL_CONSTANTS, HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IApplication } from 'src/app/core/models/api/applications/application';
import { IApplicationSearch } from 'src/app/core/models/api/applications/application-search';
import { IRole } from 'src/app/core/models/api/roles';
import { IApplicationsService } from './applications.contract';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService implements IApplicationsService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(id: number): Observable<IApplication> {
    return this.httpClient.get<IApplication>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications/${id}`);
  }

  getAll(): Observable<Array<IApplication>> {
    return this.httpClient.get<Array<IApplication>>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications`);
  }

  search(request: IApplicationSearch): Observable<IPageOf<IApplication>> {
    return this.httpClient.post<IPageOf<IApplication>>(GENERAL_CONSTANTS.ApiSecurityName, '/api/applications/search', request);
  }

  upsert(request: IApplication): Observable<void> {
    return this.httpClient.post<void>(GENERAL_CONSTANTS.ApiSecurityName, '/api/applications', request);
  }

  delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications/${id}`);
  }

  getRoles(appId: number): Observable<Array<IRole>> {
    return this.httpClient.get<Array<IRole>>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications/${appId}/roles`);
  }

  getRole(appId: number, roleId: number): Observable<IRole> {
    return this.httpClient.get<IRole>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications/${appId}/roles/${roleId}`);
  }

  upsertRole(appId: number, role: IRole): Observable<IRole> {
    return this.httpClient.post<IRole>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications/${appId}/roles`, role);
  }

  deleteRole(appId: number, roleId: number): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/applications/${appId}/roles/${roleId}`);
  }
}
