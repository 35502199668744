import { FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { IExternalCredentialSearch, IExternalCredentialSummary } from 'src/app/core/models/api/external-apps';

export class ExternalAppCredentialsViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IExternalCredentialSearch;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_EXTERNAL_APP = 'externalApp';
  readonly C_USERNAME = 'username';
  readonly C_EXTERNAL_USERNAME = 'externalUsername';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_EXTERNAL_APP]: new FormControl('', []),
      [this.C_USERNAME]: new FormControl('', []),
      [this.C_EXTERNAL_USERNAME]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IExternalCredentialSearch;
  }

  data: IExternalCredentialSummary[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'externalAppName',
      fieldHeader: $localize`:@@app.external-credentials.search.app.column:ERP`,
      canSort: true
    },
    {
      fieldName: 'userName',
      fieldHeader: $localize`:@@app.external-credentials.search.username.column:Usuario`,
      canSort: true
    },
    {
      fieldName: 'externalUserName',
      fieldHeader: $localize`:@@app.external-credentials.search.external-username.column:Usuario ERP`,
      canSort: true
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.external-credentials.search.status.column:Estado`,
      canSort: false
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.external-credentials.search.actions.edit.tooltip:Editar`
  }, {
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.WARN,
    tooltip: $localize`:@@app.external-credentials.search.actions.delete.tooltip:Desactivar`
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get searchRequest(): IExternalCredentialSearch {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get externalAppName(): string | null | undefined {
    return this._filterForm.get(this.C_EXTERNAL_APP)?.value;
  }

  set externalAppName(value: string | null | undefined) {
    this._filterForm.get(this.C_EXTERNAL_APP)?.setValue(value);
  }

  get username(): string | null | undefined {
    return this._filterForm.get(this.C_USERNAME)?.value;
  }

  set username(value: string | null | undefined) {
    this._filterForm.get(this.C_USERNAME)?.setValue(value);
  }

  get externalUserName(): string | null | undefined {
    return this._filterForm.get(this.C_EXTERNAL_USERNAME)?.value;
  }

  set externalUserName(value: string | null | undefined) {
    this._filterForm.get(this.C_EXTERNAL_USERNAME)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.externalAppName = this.externalAppName;
    this.searchRequest.userName = this.username;
    this.searchRequest.externalUserName = this.externalUserName;
  }
}
