<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.form">

    <div class="flex f-gap--10px">

      <div class="f-basis--6">
        <div class="flex f-gap--10px">
          <h3 i18n="@@app.applications.edit.application.header">Datos aplicación</h3>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.applications.edit.name.label">Nombre</mat-label>
            <input matInput [formControlName]="model.C_NAME" maxlength="100" />
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-slide-toggle
            class="f-basis--12"
            [formControlName]="model.C_STATUS"
            i18n="@@app.applications.edit.status.label"
          >
            Activa
          </mat-slide-toggle>
        </div>

        <div class="flex f-jc--center f-gap--10px f-ai--center margin-top-25">
          <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onSave()">
            <span i18n="@@app.core.actions.save">Guardar</span>
          </button>
        </div>

      </div>

      <div class="f-basis--6">

        <div class="flex f-gap--10px">
          <h3 i18n="@@app.applications.edit.roles.header">Roles</h3>
          <button
            type="button"
            mat-mini-fab mat-raised-button
            color="primary"
            matTooltip="Alta de rol"
            i18n-matTooltip="@@app.applications.role.create"
            (click)="onNewRole()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <div class="flex f-gap--10px">
          <howden-table
            keyField="id"
            paginable="local"
            sortable="local"
            filterable="global-local"
            [cols]="model.columns"
            [data]="model.roles"
            [buttons]="model.buttons"
            (buttonClicked)="onRoleAction($event)"
          >
          </howden-table>
        </div>

      </div>

    </div>

  </form>

</div>
