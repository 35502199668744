import { IExternalApp } from 'src/app/core/models/api/external-apps';
import { IExternalAppsSearchResultViewModel } from '../models/external-apps-search-result.viewmodel';

export abstract class ExternalAppsSearchMapper {
  static mapForSearch(source: IExternalApp): IExternalAppsSearchResultViewModel {
    return {
      externalAppID: source.externalAppID,
      dataSourceCode: source.dataSourceCode,
      name: source.name,
      status: source.status === 1 ? 'Activo' : 'Inactivo'
    } as IExternalAppsSearchResultViewModel;
  }
}
