import { IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { ICompany } from 'src/app/core/models/api/companies';

export class CompanySearchViewModel {
  readonly C_NAME = 'name';
  readonly C_STATUS = 'status';

  data: ICompany[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'companyName',
      fieldHeader: $localize`:@@app.companies.search.name.column:Nombre`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.companies.search.actions.edit.tooltip:Editar`
  }, {
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.WARN,
    tooltip: $localize`:@@app.companies.search.actions.delete.tooltip:Borrar`
  }];
}
