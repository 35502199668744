import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ExternalAppCredentialsSearchComponent } from './external-app-credentials-search/pages/external-app-credentials-search.component';
import { ExternalAppsSearchComponent } from './external-apps-search/pages/external-apps-search.component';

export const ExternalAppsRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'erps',
    title: $localize`:@@app.route.external-applications:ERPs`,
    data: {
      icon: 'api',
      linkText: $localize`:@@app.route.external-applications:ERPs`
    },
    children: [
      {
        path: 'external-app-search',
        component: ExternalAppsSearchComponent,
        title: $localize`:@@app.route.external-applications.search:Consulta aplicaciones`,
        data: { linkText: $localize`:@@app.route.external-applications.search:Consulta aplicaciones` }
      },
      {
        path: 'external-credentials-search',
        component: ExternalAppCredentialsSearchComponent,
        title: $localize`:@@app.route.external-credentials.search:Consulta credenciales`,
        data: { linkText: $localize`:@@app.route.external-credentials.search:Consulta credenciales` }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ExternalAppsRoutes)],
  exports: [RouterModule]
})
export class ExternalAppsRoutingModule {
}
