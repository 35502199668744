import { IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IExternalCredential, IExternalCredentialSearch, IExternalCredentialSummary } from 'src/app/core/models/api/external-apps';

export abstract class IExternalCredentialsService {
  abstract get(id: number): Observable<IExternalCredential>;
  abstract search(request: IExternalCredentialSearch): Observable<IPageOf<IExternalCredentialSummary>>;
  abstract upsert(request: IExternalCredential): Observable<void>;
  abstract deactivate(id: number): Observable<void>;
}
