import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ApplicationEditComponent } from './application-edit/pages/application-edit.component';
import { ApplicationSearchComponent } from './application-search/pages/application-search.component';

export const ApplicationsRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'applications',
    component: ApplicationSearchComponent,
    title: $localize`:@@app.route.applications:Aplicaciones`,
    data: {
      icon: 'apps',
      component: ApplicationSearchComponent,
      linkText: $localize`:@@app.route.applications:Aplicaciones`
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'applications/edit/:id',
    component: ApplicationEditComponent,
    title: $localize`:@@app.route.applications.edit:Modificar aplicación`
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ApplicationsRoutes)],
  exports: [RouterModule]
})
export class ApplicationsRoutingModule {
}
