import { IUser } from 'src/app/core/models/api/users';
import { IUserPreferences } from 'src/app/core/models/api/users/user-preferences';
import { EditUserPreferencesViewModel } from '../models';

export abstract class EditUserPreferencesMapper {
  static mapForEdit(source: IUser, target: EditUserPreferencesViewModel) {
    target.id = source.id;
    target.userName = source.userName;
    target.email = source.email;
    target.language = source.preferredLanguage;
    target.theme = source.preferredTheme;
    target.datasource = source.dataSourceActual;
  }

  static mapForUpdate(source: EditUserPreferencesViewModel): IUserPreferences {
    return {
      preferredLanguage: source.language,
      preferredTheme: source.theme,
      actualDataSource: source.datasource
    } as IUserPreferences;
  }
}
