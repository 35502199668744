import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig } from '@howdeniberia/core-front';
import { ICompany } from 'src/app/core/models/api/companies';
import { UserEmailValidator, UsernameValidator } from 'src/app/shared/validators';
import { IRoleViewModel } from './role.viewmodel';

export class RegisterUserViewModel {
  private _form: FormGroup;

  readonly C_NAME = 'name';
  readonly C_SURNAME = 'surname';
  readonly C_EMAIL = 'email';
  readonly C_PHONE_NUMBER = 'phoneNumber';
  readonly C_USERNAME = 'username';
  readonly C_PASSWORD = 'password';
  readonly C_SECONDARY_LOGO = 'secondaryLogo';

  constructor(private userNameVal: UsernameValidator, private userEmailVal: UserEmailValidator) {
    this._form = new FormGroup({
      [this.C_NAME]: new FormControl(null, [Validators.required]),
      [this.C_SURNAME]: new FormControl(null, [Validators.required]),
      [this.C_EMAIL]: new FormControl(null, {
        validators: [Validators.required, Validators.email],
        asyncValidators: [this.userEmailVal.validate.bind(this.userEmailVal)],
        updateOn: 'blur'
      }),
      [this.C_PHONE_NUMBER]: new FormControl(null, []),
      [this.C_USERNAME]: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [this.userNameVal.validate.bind(this.userNameVal)],
        updateOn: 'blur'
      }),
      [this.C_PASSWORD]: new FormControl(null, []),
      [this.C_SECONDARY_LOGO]: new FormControl(null, [])
    });
  }

  companies: ICompany[] = [];
  companiesColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'companyName',
      fieldHeader: $localize`:@@app.user.register.company.name.column:Nombre`,
      canSort: true
    }
  ];

  roles: IRoleViewModel[] = [];
  rolesColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'rolName',
      fieldHeader: $localize`:@@app.user.register.role.name.column:Rol`,
      canSort: true
    }
  ];

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get name(): string | null | undefined {
    return this.getControl(this.C_NAME)?.value;
  }

  set name(value: string | null | undefined) {
    this.getControl(this.C_NAME)?.setValue(value);
  }

  get surname(): string | null | undefined {
    return this.getControl(this.C_SURNAME)?.value;
  }

  set surname(value: string | null | undefined) {
    this.getControl(this.C_SURNAME)?.setValue(value);
  }

  get email(): string | null | undefined {
    return this.getControl(this.C_EMAIL)?.value;
  }

  set email(value: string | null | undefined) {
    this.getControl(this.C_EMAIL)?.setValue(value);
  }

  get phoneNumber(): string | null | undefined {
    return this.getControl(this.C_PHONE_NUMBER)?.value;
  }

  set phoneNumber(value: string | null | undefined) {
    this.getControl(this.C_PHONE_NUMBER)?.setValue(value);
  }

  get userName(): string | null | undefined {
    return this.getControl(this.C_USERNAME)?.value;
  }

  set userName(value: string | null | undefined) {
    this.getControl(this.C_USERNAME)?.setValue(value);
  }

  get password(): string | null | undefined {
    return this.getControl(this.C_PASSWORD)?.value;
  }

  set password(value: string | null | undefined) {
    this.getControl(this.C_PASSWORD)?.setValue(value);
  }

  get secondaryLogo(): string | null | undefined {
    return this.getControl(this.C_SECONDARY_LOGO)?.value;
  }

  set secondaryLogo(value: string | null | undefined) {
    this.getControl(this.C_SECONDARY_LOGO)?.setValue(value);
  }
}
