import { ICompany } from 'src/app/core/models/api/companies';
import { IExternalApp } from 'src/app/core/models/api/external-apps';
import { EditExternalAppViewModel } from '../models';

export abstract class EditExternalAppMapper {
  static mapForEdit(source: IExternalApp, target: EditExternalAppViewModel) {
    target.externalAppID = source.externalAppID as number;
    target.dataSourceCode = source.dataSourceCode as string;
    target.name = source.name as string;
    target.status = source.status === 1;
  }

  static mapForUpdate(source: EditExternalAppViewModel): ICompany {
    return {
      externalAppID: source.externalAppID as number,
      dataSourceCode: source.dataSourceCode as string,
      name: source.name as string,
      status: source.status ? 1 : 0
    } as ICompany;
  }
}
