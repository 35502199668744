<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.edit.company.dialog.edit.title" *ngIf="model.companyID">Modificación de empresas</div>
      <div class="title-text" i18n="@@app.edit.company.dialog.create.title" *ngIf="!model.companyID">Alta de empresas</div>
      <div class="subtitle-text"></div>
    </div>

    <mat-dialog-content>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.company.dialog.name.label">Nombre</mat-label>
          <input matInput [formControlName]="model.C_COMPANY_NAME" [howdenControlError]="companyNameError" maxlength="100" />
          <mat-error #companyNameError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-select [formControlName]="model.C_EXTERNAL_APP_ID">
            <mat-option *ngFor="let extapp of model.externalApps" [value]="extapp.externalAppID">
              {{ extapp.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
