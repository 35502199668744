import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { CompanySearchComponent } from './company-search/pages/company-search.component';

export const CompaniesRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'companies',
    title: $localize`:@@app.route.companies:Empresas`,
    component: CompanySearchComponent,
    data: {
      icon: 'apartment',
      linkText: $localize`:@@app.route.companies:Empresas`
    }
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(CompaniesRoutes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule {
}
