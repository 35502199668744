import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IExternalApp } from 'src/app/core/models/api/external-apps';

export class EditUserPreferencesViewModel {
  private _form: FormGroup;
  private _externalApps: Array<IExternalApp> = [];

  readonly C_ID = 'id';
  readonly C_USERNAME = 'username';
  readonly C_EMAIL = 'email';
  readonly C_LANGUAGE = 'language';
  readonly C_THEME = 'theme';
  readonly C_DATA_SOURCE = 'datsource';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, [Validators.required]),
      [this.C_USERNAME]: new FormControl(null, []),
      [this.C_EMAIL]: new FormControl(null, []),
      [this.C_LANGUAGE]: new FormControl(null, []),
      [this.C_THEME]: new FormControl(null, []),
      [this.C_DATA_SOURCE]: new FormControl(null, [])
    });
  }

  get externalApps(): Array<IExternalApp> {
    return this._externalApps;
  }

  set externalApps(value: Array<IExternalApp>) {
    this._externalApps = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get id(): number | null | undefined {
    return this.getControl(this.C_ID)?.value;
  }

  set id(value: number | null | undefined) {
    this.getControl(this.C_ID)?.setValue(value);
  }

  get userName(): string | null | undefined {
    return this.getControl(this.C_USERNAME)?.value;
  }

  set userName(value: string | null | undefined) {
    this.getControl(this.C_USERNAME)?.setValue(value);
  }

  get email(): string | null | undefined {
    return this.getControl(this.C_EMAIL)?.value;
  }

  set email(value: string | null | undefined) {
    this.getControl(this.C_EMAIL)?.setValue(value);
  }

  get language(): string | null | undefined {
    return this.getControl(this.C_LANGUAGE)?.value;
  }

  set language(value: string | null | undefined) {
    this.getControl(this.C_LANGUAGE)?.setValue(value);
  }

  get theme(): string | null | undefined {
    return this.getControl(this.C_THEME)?.value;
  }

  set theme(value: string | null | undefined) {
    this.getControl(this.C_THEME)?.setValue(value);
  }

  get datasource(): string | null | undefined {
    return this.getControl(this.C_DATA_SOURCE)?.value;
  }

  set datasource(value: string | null | undefined) {
    this.getControl(this.C_DATA_SOURCE)?.setValue(value);
  }
}
