import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IApplication } from 'src/app/core/models/api/applications/application';
import { IApplicationsService } from 'src/app/core/services/api/applications';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditRoleDialogComponent } from '../dialogs/edit-role-dialog/pages/edit-role-dialog.component';
import { ApplicationEditMapper } from '../mappers/application-edit.mapper';
import { ApplicationEditViewModel } from '../models';

@Component({
  selector: 'howden-application-edit',
  templateUrl: './application-edit.component.html',
  styleUrls: ['./application-edit.component.scss']
})
export class ApplicationEditComponent implements OnInit, OnDestroy {
  model = new ApplicationEditViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private appSrv: IApplicationsService
  ) {
  }

  ngOnInit() {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        const appId = parseInt(paramMap.get('id') as string);

        this.uiBlockerSrv.block();

        forkJoin([
          this.appSrv.get(appId),
          this.appSrv.getRoles(appId)
        ]).subscribe(([app, appRoles]) => {
          ApplicationEditMapper.mapForEdit(appId, app, this.model);
          this.model.roles = appRoles;
          this.uiBlockerSrv.unblock();
        });
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onSave(): void {
    const request = ApplicationEditMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();

    this.appSrv.upsert(request).pipe(
      tap({
        next: () => {
          const title: string = $localize`: @@app.applications.edit.save.dialog.title:Modificación de aplicaciones`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.reload(this.model.id));
        },
        complete: () => this.uiBlockerSrv.unblock()
      })
    ).subscribe();
  }

  onNewRole(): void {
    const inputData = { appId: this.model.id };
    const dialogRef = this.dialog.open(EditRoleDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.appSrv.getRoles(this.model.id as number).subscribe(data => this.model.roles = data);
        })
      ).subscribe();
  }

  onRoleAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEditRole(event.row.id);
    } else if (event.buttonName === 'delete') {
      this.onDelete(event.row.id);
    }
  }

  onDelete(roleId: number): void {
    const title: string = $localize`: @@app.applications.edit.delete.role.title:Borrado de roles`;
    const question: string = $localize`: @@app.applications.edit.delete.role.subtitle:Se borrará el rol, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.appSrv.deleteRole(this.model.id as number, roleId).pipe(
            tap({
              next: () => {
                this._subscriptions.sink = this.confirmDialogSrv
                  .openDefault(title)
                  .subscribe(() => {
                    this.model.roles = this.model.roles.filter(item => item.id !== roleId);
                  });
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onEditRole(roleId: number) {
    const inputData = { appId: this.model.id, roleId: roleId };
    const dialogRef = this.dialog.open(EditRoleDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.appSrv.getRoles(this.model.id as number).subscribe(data => this.model.roles = data);
        })
      ).subscribe();
  }

  private reload(id: number | null | undefined): void {
    this.uiBlockerSrv.block();
    this.appSrv.get(id as number).subscribe({
      next: (app: IApplication) => {
        ApplicationEditMapper.mapForEdit(id as number, app, this.model);
      },
      complete: () => this.uiBlockerSrv.unblock()
    });
  }
}
