import { IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IModifyUserRequest, IRegisterUserRequest, ISecondaryLogo, IUser, IUserCompany, IUserSearch } from 'src/app/core/models/api/users';
import { IUserPreferences } from 'src/app/core/models/api/users/user-preferences';

export abstract class IUsersService {
  abstract getByUsername(username: string): Observable<IUser>;
  abstract getByEmail(email: string): Observable<IUser>;
  abstract getCompanies(id: number): Observable<Array<IUserCompany>>;
  abstract getRoles(username: string): Observable<Array<string>>;
  abstract getSecondaryLogo(email: string): Observable<ISecondaryLogo>;
  abstract search(request: IUserSearch): Observable<IPageOf<IUser>>;
  abstract register(request: IRegisterUserRequest): Observable<void>;
  abstract save(request: IModifyUserRequest): Observable<void>;
  abstract addToRole(username: string, roleName: string): Observable<void>;
  abstract removeFromRole(username: string, roleName: string): Observable<void>;
  abstract addToCompany(username: string, companyID: number): Observable<IUserCompany>;
  abstract removeFromCompany(username: string, companyID: number): Observable<void>;
  abstract saveUserPreferences(email: string, preferences: IUserPreferences): Observable<void>;
  abstract setExternalPassword(username: string, password: string): Observable<void>;
}
