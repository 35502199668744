import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditExternalAppMapper } from '../mappers/edit-external-app.mapper';
import { EditExternalAppViewModel } from '../models';

@Component({
  selector: 'howden-edit-external-app-dialog',
  templateUrl: './edit-external-app-dialog.component.html',
  styleUrls: ['./edit-external-app-dialog.component.scss']
})
export class EditExternalAppDialogComponent implements OnInit, OnDestroy {
  model = new EditExternalAppViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EditExternalAppDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private externalAppsSrv: IExternalAppsService
  ) {
  }

  ngOnInit(): void {
    const externalAppID = this.inputData.externalAppID;

    if (typeof externalAppID !== 'undefined' && externalAppID !== null) {
      this.uiBlockerSrv.block();

      forkJoin([
        this.externalAppsSrv.get(externalAppID)
      ]).subscribe(([externalApp]) => {
        EditExternalAppMapper.mapForEdit(externalApp, this.model);

        this.uiBlockerSrv.unblock();
      });
    } else {
      this.model.externalAppID = null;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const request = EditExternalAppMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.externalAppsSrv.upsert(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
