import { IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IApplication } from 'src/app/core/models/api/applications/application';
import { IApplicationSearch } from 'src/app/core/models/api/applications/application-search';
import { IRole } from 'src/app/core/models/api/roles';

export abstract class IApplicationsService {
  abstract get(id: number): Observable<IApplication>;
  abstract getAll(): Observable<Array<IApplication>>;
  abstract search(request: IApplicationSearch): Observable<IPageOf<IApplication>>;
  abstract upsert(request: IApplication): Observable<void>;
  abstract delete(id: number): Observable<void>;
  abstract getRoles(appId: number): Observable<Array<IRole>>;
  abstract getRole(appId: number, roleId: number): Observable<IRole>;
  abstract upsertRole(appId: number, role: IRole): Observable<IRole>;
  abstract deleteRole(appId: number, roleId: number): Observable<void>;
}
