import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenMainLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent
} from '@howdeniberia/core-front';
import { APP_CONSTANTS } from './core/constants';
import { ApplicationsModule } from './features/applications/applications.module';
import { CompaniesModule } from './features/companies/companies.module';
import { ExternalAppsModule } from './features/external-apps/external-apps.module';
import { UsersModule } from './features/users/users.module';

export const AppRoutes = [
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    path: '',
    component: HowdenMainLayoutComponent,
    data: {
      isLayout: true,
      roles: [...APP_CONSTANTS.ROLES]
    },
    children: [
      { path: '', loadChildren: () => ApplicationsModule },
      { path: '', loadChildren: () => ExternalAppsModule },
      { path: '', loadChildren: () => CompaniesModule },
      { path: '', loadChildren: () => UsersModule }
    ]
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.routing.notauthorized: No autorizado`,
    component: NotAuthorizedComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' }
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
