import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IButtonActionData } from '@howdeniberia/core-front';
import { IExternalApp } from 'src/app/core/models/api/external-apps';
import { IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditExternalAppDialogComponent } from '../dialogs/edit-external-app-dialog/pages/edit-external-app-dialog.component';
import { ExternalAppsSearchMapper } from '../mappers';
import { ExternalAppsSearchViewModel } from '../models';

@Component({
  selector: 'howden-external-apps-search',
  templateUrl: './external-apps-search.component.html',
  styleUrls: ['./external-apps-search.component.scss']
})
export class ExternalAppsSearchComponent implements OnInit, OnDestroy {
  model = new ExternalAppsSearchViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private externalAppsSrv: IExternalAppsService
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    const externalAppID = event.row.externalAppID as number;
    if (event.buttonName === 'edit') {
      this.onEdit(externalAppID);
    }
  }

  onNewExternalApp(): void {
    const inputData = { externalAppID: null };
    const dialogRef = this.dialog.open(EditExternalAppDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.loadData());
  }

  private onEdit(externalAppID: number): void {
    const inputData = { externalAppID: externalAppID };
    const dialogRef = this.dialog.open(EditExternalAppDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.loadData());
  }

  private loadData(): void {
    this.uiBlockerSrv.block();

    this.externalAppsSrv.getAll().subscribe({
      next: (data: Array<IExternalApp>) => {
        this.model.data = data.map((source) => ExternalAppsSearchMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }
}
