import { IUser } from 'src/app/core/models/api/users';
import { IUserSearchResultViewModel } from '../models';

export abstract class UserSearchMapper {
  static mapForSearch(source: IUser): IUserSearchResultViewModel {
    return {
      id: source.id,
      name: source.name,
      surname: source.surname,
      email: source.email,
      userName: source.userName,
      preferredLanguage: source.preferredLanguage,
      preferredTheme: source.preferredTheme,
      dataSourceActual: source.dataSourceActual,
      phoneNumber: source.phoneNumber,
      hasExternalPass: source.hasExternalPass,
      status: source.status === 1 ? 'Activo' : 'Inactivo'
    } as IUserSearchResultViewModel;
  }
}
