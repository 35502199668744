import { Injectable } from '@angular/core';
import { GENERAL_CONSTANTS, HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IExternalApp } from 'src/app/core/models/api/external-apps';
import { IExternalAppsService } from './external-apps.contract';

@Injectable({
  providedIn: 'root'
})
export class ExternalAppsService implements IExternalAppsService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(id: number): Observable<IExternalApp> {
    return this.httpClient.get<IExternalApp>(GENERAL_CONSTANTS.ApiSecurityName, `/api/externalapps/${id}`);
  }

  getAll(): Observable<Array<IExternalApp>> {
    return this.httpClient.get<Array<IExternalApp>>(GENERAL_CONSTANTS.ApiSecurityName, '/api/externalapps');
  }

  upsert(request: IExternalApp): Observable<void> {
    return this.httpClient.post<void>(GENERAL_CONSTANTS.ApiSecurityName, '/api/externalapps', request);
  }

  delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/externalapps/${id}`);
  }
}
