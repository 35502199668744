import { FormControl, FormGroup } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, ITableEventData, TableButtonColors } from '@howdeniberia/core-front';
import { IApplicationSearch } from 'src/app/core/models/api/applications/application-search';
import { IApplicationSearchResultViewModel } from './application-search-result.viewmodel';

export class ApplicationSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IApplicationSearch;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_NAME = 'name';
  readonly C_STATUS = 'status';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_NAME]: new FormControl('', []),
      [this.C_STATUS]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IApplicationSearch;
  }

  data: IApplicationSearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'appId',
      fieldHeader: $localize`:@@app.applications.search.appId.column:ID`,
      canSort: false
    },
    {
      fieldName: 'name',
      fieldHeader: $localize`:@@app.applications.search.name.column:Nombre`,
      canSort: true
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.applications.search.status.column:Estado`,
      canSort: false
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.applications.search.actions.edit.tooltip:Editar`
  }];

  get length(): number { return this._length; }

  set length(length: number) { this._length = length; }

  get sortedBy(): string | undefined { return this._sortedBy; }

  get sortDirection(): string | undefined { return this._sortDirection; }

  get searchRequest(): IApplicationSearch {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get name(): string | null | undefined {
    return this._filterForm.get(this.C_NAME)?.value;
  }

  set name(value: string | null | undefined) {
    this._filterForm.get(this.C_NAME)?.setValue(value);
  }

  get status(): number | null | undefined {
    return this._filterForm.get(this.C_STATUS)?.value;
  }

  set status(value: number | null | undefined) {
    this._filterForm.get(this.C_STATUS)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    const status = this._filterForm.get(this.C_STATUS)?.value;
    this.searchRequest.name = this.name;
    this.searchRequest.status = !status || status === '' ? null : parseInt(status);
  }
}
