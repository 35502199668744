import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IRole } from 'src/app/core/models/api/roles';

export class ApplicationEditViewModel {
  private _form: FormGroup;

  readonly C_ID = 'id';
  readonly C_NAME = 'name';
  readonly C_STATUS = 'status';

  constructor() {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl('', []),
      [this.C_NAME]: new FormControl('', [Validators.required]),
      [this.C_STATUS]: new FormControl(1, [Validators.required])
    });
  }

  roles: IRole[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'rolName',
      fieldHeader: $localize`:@@app.edit.applications.name.column:Nombre`,
      canSort: true
    },
    {
      fieldName: 'isAdmin',
      fieldHeader: $localize`:@@app.edit.applications.is-admin.column:Administrador`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.CHECK
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.applications.roles.edit.tooltip:Editar`
  }, {
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.WARN,
    tooltip: $localize`:@@app.applications.roles.delete.tooltip:Borrar`
  }];

  get form(): FormGroup {
    return this._form;
  }

  set filterForm(value: FormGroup) {
    this._form = value;
  }

  get id(): number | null | undefined {
    return this._form.get(this.C_ID)?.value;
  }

  set id(value: number | null | undefined) {
    this._form.get(this.C_ID)?.setValue(value);
  }

  get name(): string | null | undefined {
    return this._form.get(this.C_NAME)?.value;
  }

  set name(value: string | null | undefined) {
    this._form.get(this.C_NAME)?.setValue(value);
  }

  get status(): boolean | null | undefined {
    return this._form.get(this.C_STATUS)?.value;
  }

  set status(value: boolean | null | undefined) {
    this._form.get(this.C_STATUS)?.setValue(value);
  }
}
