import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IExternalCredentialSummary } from 'src/app/core/models/api/external-apps';
import { IExternalCredentialsService } from 'src/app/core/services/api/external-credentials';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditExternalAppCredentialDialogComponent } from '../dialogs/edit-external-app-credential-dialog/pages/edit-external-app-credential-dialog.component';
import { ExternalAppCredentialsViewModel } from '../models';

@Component({
  selector: 'howden-external-app-credentials-search',
  templateUrl: './external-app-credentials-search.component.html',
  styleUrls: ['./external-app-credentials-search.component.scss']
})
export class ExternalAppCredentialsSearchComponent implements OnInit, OnDestroy {
  model = new ExternalAppCredentialsViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private externalCredentialSrv: IExternalCredentialsService
  ) {
  }

  ngOnInit(): void {
    this.loadData(true);
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.loadData();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEditCredential(event);
    } else if (event.buttonName === 'delete') {
      this.onDeactivateCredential(event);
    }
  }

  onNewCredential(): void {
    const data = { id: null };
    const dialogRef = this.dialog.open(EditExternalAppCredentialDialogComponent, {
      minWidth: '860px',
      width: 'auto',
      data: data
    });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(tap(() => this.loadData()))
      .subscribe();
  }

  onEditCredential(event: IButtonActionData): void {
    const data = { id: event.row.id };
    const dialogRef = this.dialog.open(EditExternalAppCredentialDialogComponent, {
      minWidth: '860px',
      width: 'auto',
      data: data
    });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(tap(() => this.loadData()))
      .subscribe();
  }

  onDeactivateCredential(event: IButtonActionData): void {
    const title: string = $localize`: @@app.external-credentials.search.deactivate.title:Desactivación de credenciales`;
    const question: string = $localize`: @@app.external-credentials.search.deactivate.subtitle:Se desactivará la credencial, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.externalCredentialSrv.deactivate(event.row.id).pipe(
            tap({
              next: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.loadData());
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private loadData(initial?: boolean): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.externalCredentialSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IExternalCredentialSummary>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data;
      },
      complete: () => {
        this.uiBlockerSrv.unblock();

        if (initial === true) {
          // this.nameInputRef.nativeElement.focus();
        }
      }
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.loadData();
    });
  }
}
