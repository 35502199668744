<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.external-credentials.search.app.label">ERP</mat-label>
        <input #nameInput matInput [formControlName]="model.C_EXTERNAL_APP"/>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.external-credentials.search.username.label">Usuario</mat-label>
        <input #nameInput matInput [formControlName]="model.C_USERNAME"/>
      </mat-form-field>

      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.external-credentials.search.external-username.label">Usuario (ERP)</mat-label>
        <input #nameInput matInput [formControlName]="model.C_EXTERNAL_USERNAME"/>
      </mat-form-field>

      <button
        type="button"
        mat-mini-fab mat-raised-button
        color="primary"
        matTooltip="Alta de aplicación"
        i18n-matTooltip="@@app.applications.create"
        (click)="onNewCredential()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </form>
  <div>
    <howden-table
      keyField="appId"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
