import { Injectable } from '@angular/core';
import { GENERAL_CONSTANTS, HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { ICompany } from 'src/app/core/models/api/companies';
import { ICompaniesService } from './companies.contract';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService implements ICompaniesService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(id: number): Observable<ICompany> {
    return this.httpClient.get<ICompany>(GENERAL_CONSTANTS.ApiSecurityName, `/api/companies/${id}`);
  }

  getAll(): Observable<Array<ICompany>> {
    return this.httpClient.get<Array<ICompany>>(GENERAL_CONSTANTS.ApiSecurityName, '/api/companies');
  }

  upsert(request: ICompany): Observable<void> {
    return this.httpClient.post<void>(GENERAL_CONSTANTS.ApiSecurityName, '/api/companies', request);
  }

  delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/companies/${id}`);
  }
}
