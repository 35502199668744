<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.edit.user.preferences.title">Preferencias de usuario</div>
      <div class="subtitle-text">{{ model.userName }} ( {{ model.email }} )</div>
    </div>

    <mat-dialog-content>
      <div class="flex">
        <h2 i18n="@@app.edit.user.preferences.data.header">Datos usuario</h2>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.user.preferences.language.label">Idioma</mat-label>
          <mat-select [formControlName]="model.C_LANGUAGE" [howdenControlError]="languageError">
            <mat-option></mat-option>
            <mat-option value="es" i18n="@@core.user-settings.es">Español</mat-option>
            <mat-option value="en" i18n="@@core.user-settings.fr">Francés</mat-option>
            <mat-option value="fr" i18n="@@core.user-settings.en">Inglés</mat-option>
            <mat-option value="pt" i18n="@@core.user-settings.pt">Portugués</mat-option>
          </mat-select>
          <mat-error #languageError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.user.preferences.theme.label">Tema</mat-label>
          <mat-select [formControlName]="model.C_THEME" [howdenControlError]="themeError">
            <mat-option></mat-option>
            <mat-option value="light" i18n="@@app.edit.user.preferences.theme.light">Claro</mat-option>
            <mat-option value="dark" i18n="@@app.edit.user.preferences.theme.dark">Oscuro</mat-option>
          </mat-select>
          <mat-error #themeError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <howden-autocomplete
          class="f-basis--12"
          i18n-label="@@app.edit.user.preferences.external-app.label"
          label="ERP"
          displayField="name"
          keyField="dataSourceCode"
          [selectableItems]="model.externalApps"
          [formControlName]="model.C_DATA_SOURCE"
        ></howden-autocomplete>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="this.model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
