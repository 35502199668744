import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export class EditExternalAppViewModel {
  private _form: FormGroup;

  readonly C_EXTERNAL_APP_ID = 'externalAppId';
  readonly C_DATA_SOURCE_CODE = 'dataSourceCode';
  readonly C_NAME = 'name';
  readonly C_STATUS = 'status';

  constructor() {
    this._form = new FormGroup({
      [this.C_EXTERNAL_APP_ID]: new FormControl(null, []),
      [this.C_DATA_SOURCE_CODE]: new FormControl(null, [Validators.required]),
      [this.C_NAME]: new FormControl(null, [Validators.required]),
      [this.C_STATUS]: new FormControl(null, [])
    });
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get externalAppID(): number | null | undefined {
    return this.getControl(this.C_EXTERNAL_APP_ID).value;
  }

  set externalAppID(value: number | null | undefined) {
    this.getControl(this.C_EXTERNAL_APP_ID).setValue(value);
  }

  get dataSourceCode(): string {
    return this.getControl(this.C_DATA_SOURCE_CODE).value;
  }

  set dataSourceCode(value: string) {
    this.getControl(this.C_DATA_SOURCE_CODE).setValue(value);
  }

  get name(): string {
    return this.getControl(this.C_NAME).value;
  }

  set name(value: string) {
    this.getControl(this.C_NAME).setValue(value);
  }

  get status(): boolean | null | undefined {
    return this._form.get(this.C_STATUS)?.value;
  }

  set status(value: boolean | null | undefined) {
    this._form.get(this.C_STATUS)?.setValue(value);
  }
}
