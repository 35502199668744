import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HowdenAskForDataModule,
  HowdenAutocompleteModule,
  HowdenDecimalModule,
  HowdenFileUploadModule,
  HowdenLabelValueModule,
  HowdenPageTitleModule,
  HowdenSharedModule,
  HowdenTableModule
} from '@howdeniberia/core-front';
import { ApplicationsService, IApplicationsService } from 'src/app/core/services/api/applications';
import { CompaniesService, ICompaniesService } from 'src/app/core/services/api/companies';
import { ExternalAppsService, IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { IRolesService, RolesService } from 'src/app/core/services/api/roles';
import { IUsersService, UsersService } from 'src/app/core/services/api/users';
import { ConfirmationDialogSrv, IConfirmationDialogSrv, IUIBlockerService, UIBlockerService } from 'src/app/core/services/ui';
import { EditUserDialogComponent } from './user-search/dialogs/edit-user-dialog/pages/edit-user-dialog.component';
import { EditUserPreferencesDialogComponent } from './user-search/dialogs/edit-user-preferences-dialog/pages/edit-user-preferences-dialog.component';
import { RegisterUserDialogComponent } from './user-search/dialogs/register-user-dialog/pages/register-user-dialog.component';
import { UserSearchComponent } from './user-search/pages/user-search.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    MatTooltipModule,
    HowdenFileUploadModule,
    HowdenSharedModule,
    HowdenDecimalModule,
    HowdenPageTitleModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    HowdenAutocompleteModule,
    HowdenAskForDataModule,
    UsersRoutingModule
  ],
  declarations: [
    UserSearchComponent,
    RegisterUserDialogComponent,
    EditUserDialogComponent,
    EditUserPreferencesDialogComponent
  ],
  providers: [
    { provide: IConfirmationDialogSrv, useClass: ConfirmationDialogSrv },
    { provide: IUIBlockerService, useClass: UIBlockerService },
    { provide: IUsersService, useClass: UsersService },
    { provide: IApplicationsService, useClass: ApplicationsService },
    { provide: IExternalAppsService, useClass: ExternalAppsService },
    { provide: ICompaniesService, useClass: CompaniesService },
    { provide: IRolesService, useClass: RolesService },
    { provide: IUsersService, useClass: UsersService }
  ]
})
export class UsersModule {
}
