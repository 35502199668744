import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IApplicationsService } from 'src/app/core/services/api/applications';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditRoleMapper } from '../mappers';
import { EditRoleViewModel } from '../models';

@Component({
  selector: 'howden-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss']
})
export class EditRoleDialogComponent implements OnInit, OnDestroy {
  model = new EditRoleViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EditRoleDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private appSrv: IApplicationsService
  ) {
  }

  ngOnInit(): void {
    const appId = this.inputData.appId;
    const roleId = this.inputData.roleId;

    if ((typeof appId !== 'undefined' && appId !== null)
      && (typeof roleId !== 'undefined' && roleId !== null)) {
      this.uiBlockerSrv.block();

      forkJoin([
        this.appSrv.getRole(appId, roleId)
      ]).subscribe(([role]) => {
        EditRoleMapper.mapForEdit(role, this.model);

        this.uiBlockerSrv.unblock();
      });
    } else {
      this.model.appId = appId;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const appId = this.inputData.appId;
    const request = EditRoleMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.appSrv.upsertRole(appId, request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
