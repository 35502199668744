import { IRole } from 'src/app/core/models/api/roles';
import { EditRoleViewModel } from '../models';

export abstract class EditRoleMapper {
  static mapForEdit(source: IRole, target: EditRoleViewModel) {
    target.roleId = source.id as number;
    target.appId = source.appId as number;
    target.name = source.rolName as string;
    target.isAdmin = source.isAdmin;
  }

  static mapForUpdate(source: EditRoleViewModel): IRole {
    return {
      id: source.roleId,
      appId: source.appId,
      rolName: source.name,
      isAdmin: source.isAdmin
    } as IRole;
  }
}
