import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogResultTypes, IButtonActionData, IDialogResult } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { ICompany } from 'src/app/core/models/api/companies';
import { ICompaniesService } from 'src/app/core/services/api/companies';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditCompanyDialogComponent } from '../dialogs/edit-company-dialog/pages/edit-company-dialog.component';
import { CompanySearchViewModel } from '../models/company-search.viewmodel';

@Component({
  selector: 'howden-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit, OnDestroy {
  model = new CompanySearchViewModel();

  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private companiesSrv: ICompaniesService
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAction(event: IButtonActionData): void {
    const companyID = event.row.companyID as number;
    if (event.buttonName === 'edit') {
      this.onEdit(companyID);
    } else {
      this.onDelete(companyID);
    }
  }

  onNewCompany(): void {
    // Create
    const inputData = { companyID: null };
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.loadData());
  }

  private onEdit(companyID: number): void {
    const inputData = { companyID: companyID };
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      minWidth: '400px',
      width: 'auto',
      data: inputData
    });
    this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => this.loadData());
  }

  private onDelete(companyID: number): void {
    const title: string = $localize`: @@app.applications.edit.delete.title:Borrado de empresas`;
    const question: string = $localize`: @@app.applications.edit.delete.subtitle:Se borrará la empresa, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          this.uiBlockerSrv.block();
          this.companiesSrv.delete(companyID).pipe(
            tap({
              next: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title).subscribe(() => this.loadData());
              },
              complete: () => this.uiBlockerSrv.unblock()
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private loadData(): void {
    this.uiBlockerSrv.block();

    this.companiesSrv.getAll().subscribe({
      next: (data: Array<ICompany>) => {
        this.model.data = data;
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }
}
