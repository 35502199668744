<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.edit.user.title">Modificación de usuarios</div>
      <div class="subtitle-text">{{ model.userName }} ( {{ model.email }} )</div>
    </div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <div class="f-basis--6">
          <div class="flex">
            <h2 i18n="@@app.edit.user.data.header">Datos usuario</h2>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.name.label">Nombre</mat-label>
              <input matInput [formControlName]="model.C_NAME" [howdenControlError]="userNameError" maxlength="100" />
              <mat-error #userNameError></mat-error>
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.surname.label">Apellidos</mat-label>
              <input matInput [formControlName]="model.C_SURNAME" [howdenControlError]="userSurnameError" maxlength="250" />
              <mat-error #userSurnameError></mat-error>
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.phone.label">Telefono</mat-label>
              <input matInput [formControlName]="model.C_PHONE_NUMBER" [howdenControlError]="userPhoneError" maxlength="100" />
              <mat-error #userPhoneError></mat-error>
            </mat-form-field>
          </div>
          <div class="flex margin-bottom-20">
            <mat-slide-toggle class="f-basis--12" [formControlName]="model.C_STATUS" i18n="@@app.edit.user.status.label">
              Activo
            </mat-slide-toggle>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.new-username.label">Nuevo usuario</mat-label>
              <input matInput [formControlName]="model.C_NEW_USERNAME" [howdenControlError]="newUserNameError" maxlength="256" />
              <mat-error #newUserNameError></mat-error>
              <mat-error
                *ngIf="model.getControl(model.C_USERNAME).hasError('notAvailable')"
                i18n="@@app.edit.user.username.not.available.error">El nombre de usuario indicado no está disponible.</mat-error>
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.password.label">Contraseña</mat-label>
              <input
                matInput
                [formControlName]="model.C_PASSWORD"
                [howdenControlError]="userPasswordError"
                type="password"
                maxlength="100"
              />
              <mat-error #userPasswordError></mat-error>
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.new-password.label">Nueva contraseña</mat-label>
              <input
                matInput
                [formControlName]="model.C_NEW_PASSWORD"
                [howdenControlError]="newUserPasswordError"
                type="password"
                maxlength="100"
              />
              <mat-error #newUserPasswordError></mat-error>
            </mat-form-field>
          </div>
          <div class="flex">
            <mat-form-field class="f-basis--12">
              <mat-label i18n="@@app.edit.user.secondary.logo.label">Logo secundario</mat-label>
              <textarea
                matInput
                [formControlName]="model.C_SECONDARY_LOGO"
                [howdenControlError]="secondaryLogoError"
                cdkTextareaAutosize
                cdkAutosizeMinRows="6"
              >
              </textarea>
              <mat-error #secondaryLogoError></mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div class="f-basis--6">
          <div class="flex">
            <h2 i18n="@@app.edit.user.companies.header">Empresas</h2>
          </div>
          <div class="flex">
            <howden-autocomplete
              class="f-basis--10"
              i18n-label="@@app.edit.user.companies.list.label"
              label="Empresa"
              displayField="companyName"
              keyField="companyID"
              [selectableItems]="model.companies"
              [formControlName]="model.C_NEW_COMPANY"
            >
            </howden-autocomplete>
            <button
              class="margin-left-10"
              type="button"
              mat-mini-fab mat-raised-button
              color="primary"
              matTooltip="Asignar compañía"
              i18n-matTooltip="@@app.user.edit.company.assign.button.text"
              [disabled]="!model.newCompany"
              (click)="onAssignCompany()"
            >
              <mat-icon>assignment_add</mat-icon>
            </button>
          </div>
          <div class="flex">
            <howden-table
              #companiesTable
              class="small-font"
              keyField="companyID"
              sortable="local"
              [cols]="model.userCompaniesColumns"
              [data]="model.userCompanies"
              [buttons]="model.userCompaniesButtons"
              (buttonClicked)="onCompanyAction($event)"
            >
            </howden-table>
          </div>
          <div class="flex">
            <mat-divider></mat-divider>
          </div>
          <div class="flex">
            <h2 i18n="@@app.edit.user.roles.header">Roles</h2>
          </div>
          <div class="flex">
            <howden-autocomplete
              class="f-basis--10"
              i18n-label="@@app.edit.user.roles.list.label"
              label="Rol"
              displayField="rolName"
              keyField="rolName"
              [selectableItems]="model.roles"
              [formControlName]="model.C_NEW_ROLE"
            >
            </howden-autocomplete>
            <button
              class="margin-left-10"
              type="button"
              mat-mini-fab mat-raised-button
              color="primary"
              matTooltip="Asignar rol"
              i18n-matTooltip="@@app.user.edit.role.assign.button.text"
              [disabled]="!model.newRole"
              (click)="onAssignRole()"
            >
              <mat-icon>assignment_add</mat-icon>
            </button>
          </div>
          <div class="flex">
            <mat-divider></mat-divider>
          </div>
          <mat-divider></mat-divider>
          <div class="flex">
            <howden-table
              #rolesTable
              class="small-font"
              paginable="local"
              keyField="rolName"
              sortable="local"
              [pageSize]="5"
              [cols]="model.rolesColumns"
              [data]="model.userRoles"
              [buttons]="model.rolesButtons"
              (buttonClicked)="onRoleAction($event)"
            >
            </howden-table>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="disableAccept" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
