<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <div class="flex f-jc--end f-gap--10px f-ai--center margin-top-25">
    <button
      type="button"
      mat-mini-fab mat-raised-button
      color="primary"
      matTooltip="Alta de ERP"
      i18n-matTooltip="@@app.external-app.create"
      (click)="onNewExternalApp()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div>
    <howden-table
      keyField="externalAppID"
      paginable="local"
      sortable="local"
      filterable="global-local"
      [cols]="model.columns"
      [data]="model.data"
      [buttons]="model.buttons"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
