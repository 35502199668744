import { IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { IExternalAppsSearchResultViewModel } from './external-apps-search-result.viewmodel';

export class ExternalAppsSearchViewModel {
  readonly C_NAME = 'name';
  readonly C_STATUS = 'status';

  data: IExternalAppsSearchResultViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'name',
      fieldHeader: $localize`:@@app.external-apps.search.name.column:Nombre`,
      canSort: true
    },
    {
      fieldName: 'dataSourceCode',
      fieldHeader: $localize`:@@app.external-apps.search.data-source-code.column:Código`,
      canSort: true
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.external-apps.search.status.column:Estado`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [{
    name: 'edit',
    icon: 'edit',
    color: TableButtonColors.PRIMARY,
    tooltip: $localize`:@@app.external-apps.search.actions.edit.tooltip:Editar`
  }];
}
