import { Injectable } from '@angular/core';
import { GENERAL_CONSTANTS, HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IExternalCredential, IExternalCredentialSearch, IExternalCredentialSummary } from 'src/app/core/models/api/external-apps';
import { IExternalCredentialsService } from './external-credentials.contract';

@Injectable({
  providedIn: 'root'
})
export class ExternalCredentialsService implements IExternalCredentialsService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(id: number): Observable<IExternalCredential> {
    return this.httpClient.get<IExternalCredential>(GENERAL_CONSTANTS.ApiSecurityName, `/api/externalcredentials/${id}`);
  }

  search(request: IExternalCredentialSearch): Observable<IPageOf<IExternalCredentialSummary>> {
    return this.httpClient.post<IPageOf<IExternalCredentialSummary>>(GENERAL_CONSTANTS.ApiSecurityName, '/api/externalcredentials/search', request);
  }

  upsert(request: IExternalCredential): Observable<void> {
    return this.httpClient.post<void>(GENERAL_CONSTANTS.ApiSecurityName, '/api/externalcredentials', request);
  }

  deactivate(id: number): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/externalcredentials/${id}`);
  }
}
