import { IApplication } from 'src/app/core/models/api/applications/application';
import { IApplicationSearchResultViewModel } from '../models';

export abstract class ApplicationSearchMapper {
  static mapForSearch(source: IApplication): IApplicationSearchResultViewModel {
    return {
      appId: source.appId,
      name: source.name,
      status: source.status === 1 ? 'Activo' : 'Inactivo'
    } as IApplicationSearchResultViewModel;
  }
}
