import { IApplication } from 'src/app/core/models/api/applications/application';
import { ApplicationEditViewModel } from '../models';

export abstract class ApplicationEditMapper {
  static mapForEdit(id: number, source: IApplication, target: ApplicationEditViewModel) {
    target.id = id;
    target.name = source.name;
    target.status = source.status === 1;
  }

  static mapForUpdate(source: ApplicationEditViewModel): IApplication {
    return {
      appId: source.id,
      name: source.name,
      status: source.status !== false ? 1 : 0
    } as IApplication;
  }
}
