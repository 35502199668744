<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">
    <div mat-dialog-title class="margin-bottom-0">
      <div class="title-text" i18n="@@app.edit.external-app.edit.title" *ngIf="model.externalAppID">Modificación de ERP</div>
      <div class="title-text" i18n="@@app.edit.external-app.create.title" *ngIf="!model.externalAppID">Alta de ERP</div>
      <div class="subtitle-text"></div>
    </div>

    <mat-dialog-content>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.external-app.dialog.name.label">Nombre</mat-label>
          <input matInput [formControlName]="model.C_NAME" [howdenControlError]="appNameError" maxlength="50" />
          <mat-error #appNameError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.edit.external-app.dialog.code.label">Código</mat-label>
          <input matInput [formControlName]="model.C_DATA_SOURCE_CODE" [howdenControlError]="datasourceCodeError" maxlength="15" />
          <mat-error #datasourceCodeError></mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-slide-toggle
          class="f-basis--12"
          [formControlName]="model.C_STATUS"
          i18n="@@app.edit.external-app.dialog.status.label"
        >
          Activo
        </mat-slide-toggle>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>
  </div>
</form>
