import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { UserSearchComponent } from './user-search/pages/user-search.component';

export const UsersRoutes = [
  {
    canActivate: [AuthGuard],
    path: 'users',
    component: UserSearchComponent,
    title: $localize`:@@app.route.users:Usuarios`,
    data: {
      icon: 'manage_accounts',
      linkText: $localize`:@@app.route.users:Usuarios`
    }
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(UsersRoutes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {
}
